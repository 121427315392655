body {
  overflow-x: hidden;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.text-muted {
  color: #777;
}
.text-primary {
  color: #09a681;
}
.text-white {
  color: white;
}
p {
  font-size: 14px;
  line-height: 1.85;
}
p.large {
  font-size: 16px;
}
a,
a:hover,
a:focus,
a:active,
a.active {
  outline: none;
}
a {
  color: #09a681;
}
a:hover,
a:focus,
a:active,
a.active {
  color: #06765b;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.img-centered {
  margin: 0 auto;
}
.bg-light-gray {
  background-color: #f7f7f7;
}
.bg-darkest-gray {
  background-color: #3c3c3b;
}
.btn-primary {
  color: white;
  background-color: #09a681;
  border-color: #09a681;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: white;
  background-color: #06765b;
  border-color: #066c54;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #09a681;
  border-color: #09a681;
}
.btn-primary .badge {
  color: #09a681;
  background-color: white;
}
.btn-xl {
  color: white;
  background-color: #09a681;
  border-color: #09a681;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  padding: 20px 40px;
}
.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  color: white;
  background-color: #06765b;
  border-color: #066c54;
}
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  background-image: none;
}
.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
  background-color: #09a681;
  border-color: #09a681;
}
.btn-xl .badge {
  color: #09a681;
  background-color: white;
}
.navbar-default {
  background-color: white;
  border-color: transparent;
  min-height: 100px;
}
.navbar-default .navbar-brand {
  color: #09a681;
  font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:active,
.navbar-default .navbar-brand.active {
  color: #06765b;
}
.navbar-default .navbar-collapse {
  border-color: rgba(255, 255, 255, 0.02);
}
.navbar-default .navbar-toggle {
  background-color: #09a681;
  border-color: #09a681;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #09a681;
}
.navbar-default .nav li a {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  color: #3c3c3b;
}
.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus {
  color: #09a681;
  outline: none;
}
.navbar-default .navbar-nav > .active > a {
  border-radius: 0;
  color: white;
  background-color: #09a681;
}
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: white;
  background-color: #06765b;
}
@media (min-width: 768px) {
  .navbar-default {
    background-color: white;
    min-height: 100px;
    transition: padding 0.3s;
    border: none;
  }
  .navbar-default .navbar-brand {
    font-size: 2em;
    transition: all 0.3s;
  }
  .navbar-default .navbar-nav > .active > a {
    border-radius: 3px;
  }
  .navbar-default.navbar-shrink {
    background-color: #3c3c3b;
    padding: 10px 0;
  }
  .navbar-default.navbar-shrink .navbar-brand {
    font-size: 1.5em;
  }
}
ul.navbar-right {
  margin-top: 25px;
}
@media (max-width: 767px) {
  .navbar-default {
    min-height: 70px;
  }
  .navbar-brand img {
    width: 200px;
  }
  .navbar-toggle {
    margin-top: 20px;
  }
}
header {
  background-image: url('../../img/bg_header.jpg');
  background-repeat: none;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  -o-background-size: cover;
  text-align: center;
  color: white;
  margin-top: 100px;
}
header .intro-text {
  padding-top: 100px;
  padding-bottom: 50px;
}
header .intro-text .intro-lead-in {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 25px;
}
header .intro-text .intro-heading {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 50px;
  line-height: 36px;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  header .intro-text {
    padding-top: 250px;
    padding-bottom: 250px;
  }
  header .intro-text .intro-lead-in {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 25px;
  }
  header .intro-text .intro-heading {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 60px;
    line-height: 75px;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  header {
    margin-top: 70px;
  }
  header .intro-text .intro-heading {
    font-size: 30px;
  }
}
@media (max-width: 767px) and (orientation: portrait) {
  header .intro-text {
    padding-top: 140px;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  header .intro-text {
    padding-top: 30px;
  }
}
section {
  padding: 100px 0;
}
section h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}
section h3.section-subheading {
  font-size: 16px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  /*font-style: italic;*/
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 75px;
}
@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}
.profile-heading {
  margin: 15px 0;
  text-transform: none;
}
h4.profile-heading {
  font-size: 22px;
  color: #007888;
}
@media (max-width: 767px) {
  h4.profile-heading {
    font-size: 18px;
  }
}
#portfolio .portfolio-item {
  margin: 0 0 15px;
  right: 0;
}
#portfolio .portfolio-item .portfolio-link {
  display: block;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  background: rgba(9, 166, 129, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
}
#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
  opacity: 1;
}
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  position: absolute;
  width: 100%;
  height: 20px;
  font-size: 20px;
  text-align: center;
  top: 50%;
  margin-top: -12px;
  color: white;
}
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
  margin-top: -12px;
}
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
  margin: 0;
}
#portfolio .portfolio-item .portfolio-caption {
  max-width: 400px;
  margin: 0 auto;
  background-color: white;
  text-align: center;
  padding: 25px;
}
#portfolio .portfolio-item .portfolio-caption h4 {
  text-transform: none;
  margin: 0;
}
#portfolio .portfolio-item .portfolio-caption p {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 16px;
  margin: 0;
}
#portfolio * {
  z-index: 2;
}
@media (min-width: 767px) {
  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
}
.team-member {
  text-align: center;
  margin-bottom: 50px;
}
.team-member img {
  margin: 0 auto;
  border: 7px solid white;
}
.team-member h4 {
  margin-top: 25px;
  margin-bottom: 0;
  text-transform: none;
}
.team-member p {
  margin-top: 0;
}
aside.clients img {
  margin: 50px auto;
}
section#kontakt {
  background-color: #007888;
  background-image: url('../../img/bg_footer.jpg');
  background-repeat: none;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}
section#kontakt .section-heading {
  color: white;
}
section#kontakt .form-group {
  margin-bottom: 25px;
}
section#kontakt .form-group input,
section#kontakt .form-group textarea {
  padding: 20px;
}
section#kontakt .form-group input.form-control {
  height: auto;
  background-color: rgba(255, 255, 255, 0.8);
}
section#kontakt .form-group textarea.form-control {
  height: 236px;
  background-color: rgba(255, 255, 255, 0.8);
}
section#kontakt .form-control:focus {
  border-color: #09a681;
  box-shadow: none;
}
section#kontakt ::-webkit-input-placeholder {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #949494;
}
section#kontakt :-moz-placeholder {
  /* Firefox 18- */
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #949494;
}
section#kontakt ::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #949494;
}
section#kontakt :-ms-input-placeholder {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #949494;
}
section#kontakt .text-danger {
  color: #e74c3c;
}
section#kontakt .small {
  color: white;
}
footer {
  padding: 25px 0;
  text-align: center;
}
footer span.copyright {
  line-height: 40px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-transform: none;
}
footer ul.quicklinks {
  margin-bottom: 0;
  line-height: 40px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-transform: none;
}
ul.social-buttons {
  margin-bottom: 0;
}
ul.social-buttons li a {
  display: block;
  background-color: #3c3c3b;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  font-size: 20px;
  line-height: 40px;
  color: white;
  outline: none;
  transition: all 0.3s;
}
ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
  background-color: #09a681;
}
.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
  outline: none;
}
.portfolio-modal .modal-content {
  border-radius: 0;
  background-clip: border-box;
  box-shadow: none;
  border: none;
  min-height: 100%;
  padding: 100px 0;
  text-align: center;
}
.portfolio-modal .modal-content h2 {
  margin-bottom: 15px;
  font-size: 3em;
}
.portfolio-modal .modal-content p {
  margin-bottom: 30px;
}
.portfolio-modal .modal-content p.item-intro {
  margin: 20px 0 30px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 16px;
}
.portfolio-modal .modal-content ul.list-inline {
  margin-bottom: 30px;
  margin-top: 0;
}
.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}
.portfolio-modal .close-modal {
  position: absolute;
  width: 75px;
  height: 75px;
  background-color: transparent;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}
.portfolio-modal .close-modal .lr {
  height: 75px;
  width: 1px;
  margin-left: 35px;
  background-color: #3c3c3b;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  z-index: 1051;
}
.portfolio-modal .close-modal .lr .rl {
  height: 75px;
  width: 1px;
  background-color: #3c3c3b;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  z-index: 1052;
}
.portfolio-modal .modal-backdrop {
  opacity: 0;
  display: none;
}
::-moz-selection {
  text-shadow: none;
  background: #09a681;
}
::selection {
  text-shadow: none;
  background: #09a681;
}
img::-moz-selection {
  background: transparent;
}
img::selection {
  background: transparent;
}
img::-moz-selection {
  background: transparent;
}
body {
  webkit-tap-highlight-color: #09a681;
}
/* utility */
.padding-bottom {
  padding-bottom: 20px;
}
.rtl {
  direction: rtl;
  unicode-bidi: bidi-override;
}
.form-error {
  color: white;
}

/*# sourceMappingURL=app.css.map */
